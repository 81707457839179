import request from '../utils/request';

/**
 * 获取所有区号
 */
export const getSendCode = (data: any) => {
    return request({
        url: '/seller-center/landing/page/send',
        method: 'post',
        data
    });
};

/**
 * 获取所有区号
 */
export const setUpShopSubmit = (data: any) => {
    return request({
        url: '/seller-center/landing/page/submit',
        method: 'post',
        data
    });
};

/**
 * 获取所有国家列表, 面前是6个
 * @returns {AxiosPromise}
 */
export const getAllCountryList = () => {
    return request({
        url: '/seller-center/system/allCountries',
        method: 'get'
    });
};
