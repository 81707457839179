

















































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { getSendCode, setUpShopSubmit, getAllCountryList } from '@/api/EGGMall';
import { AppModule } from '@/store/modules/app';

@Component
export default class extends Vue {
    $i18n: any;
    $refs: any;
    $message: any;

    @PropSync('visible', {
        required: true,
        default: false
    })
    dialogVisible!: any;

    @Prop({
        required: true,
        default: 0
    }) currentCountyId!: number; // 通过IP获取的当前国家id

    @Prop({
        default: 5
    }) source!: number

    setUpShopModel: any = {
        mobile: '',
        verifyCode: '',
        name: '',
        contactPerson: '',
        detailAddress: '',
        email: '',
        prex: '',
        areaCodeObj: '',
        applySystemType: 5
    };

    canGetCode = false;

    countdown = 0;

    submitLoading = false;

    countryList: any[] = []

    get setUpShopRules() {
        const mobileValidator = (rule: any, value: string, callback: any) => {
            this.canGetCode = false;
            if (!value) return callback(this.$i18n.t('landingWeb.fieldRequired'));
            if (this.setUpShopModel.areaCodeObj) {
                const mobileRule = new RegExp(this.setUpShopModel.areaCodeObj.mobileRule);
                if (!mobileRule.test(value)) return callback(this.$i18n.t('EGGMall.checkFormat'));
            }
            this.canGetCode = true;
            callback();
        };
        const codeValidator = (rule:any, value:string, callback:any) => {
            if (!value) return callback(this.$i18n.t('landingWeb.fieldRequired'));
            const isValidCode = (str: string) => /^\d{4}$/.test(str);
            if (!isValidCode(value)) return callback(this.$i18n.t('EGGMall.checkFormat'));
            callback();
        };
        const emailValidator = (rule:any, value:string, callback:any) => {
            const isValidEmail = (str:string) => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(str);
            if (value && !isValidEmail(value)) {
                return callback(this.$i18n.t('EGGMall.checkFormat'));
            }
            callback();
        };
        return {
            mobile: [
                { required: true, validator: mobileValidator, trigger: ['change', 'blur'] }
            ],
            verifyCode: [
                { required: true, validator: codeValidator, trigger: ['change', 'blur'] }
            ],
            contactPerson: [
                { required: true, trigger: ['change', 'blur'] }
            ],
            email: [
                { validator: emailValidator, trigger: 'change' }
            ]
        };
    }

    @Watch('countryList', { deep: true, immediate: true })
    watchCountryList(newValue: any[]) {
        if (newValue.length) {
            if (this.currentCountyId) this.setUpShopModel.areaCodeObj = newValue.find(val => val.countryId === this.currentCountyId);
            if (!this.setUpShopModel.areaCodeObj) this.setUpShopModel.areaCodeObj = newValue[0];
        }
    }

    @Watch('currentCountyId', { deep: true, immediate: true })
    watchCurrentCountyId(newValue: number[]) {
        if (newValue) {
            if (this.setUpShopModel.length) this.setUpShopModel.areaCodeObj = newValue.find((val: any) => val.countryId === this.currentCountyId);
        }
    }

    created() {
        this.getAllCountryList();
    }

    getAllCountryList() {
        getAllCountryList().then(res => {
            res.data.forEach((item: any) => {
                if (!item.areaCode.startsWith('+')) {
                    item.areaCode = '+' + item.areaCode;
                }
            });
            this.countryList = res.data;
        });
    }

    changeAreaCode() {
        // 重新校验mobile
        if (this.setUpShopModel.mobile) this.$refs.setUpShopForm.validateField('mobile');
    }

    getCode() {
        // 获取验证码 api
        this.countdown = 60;
        let timer: any = setInterval(() => {
            this.countdown--;
            if (this.countdown === 0 && timer) {
                clearInterval(timer);
                timer = null;
            }
        }, 1000);
        getSendCode({
            prefix: (this.setUpShopModel.areaCodeObj.areaCode).match(/\d+/g).join(''),
            cellphone: this.setUpShopModel.mobile,
            applySystemType: 5
        }).then(() => {
            this.$message.success(this.$i18n.t('EGGMall.send_code_success'));
        }).catch(() => {
            if (timer) {
                this.countdown = 0;
                clearInterval(timer);
                timer = null;
            }
        });
    }

    setUpSubmit() {
        this.$refs.setUpShopForm.validate((valid: boolean) => {
            if (valid) {
                this.submitLoading = true;
                setUpShopSubmit({
                    detailAddress: this.setUpShopModel.detailAddress,
                    email: this.setUpShopModel.email,
                    mobile: this.setUpShopModel.mobile,
                    name: this.setUpShopModel.name,
                    contactPerson: this.setUpShopModel.contactPerson,
                    prex: (this.setUpShopModel.areaCodeObj.areaCode).match(/\d+/g).join(''),
                    verifyCode: this.setUpShopModel.verifyCode,
                    applySystemType: 5,
                    submitSource: this.source,
                    businessPlatform: navigator.userAgent.match(/mobile/i) ? 201 : 200
                }).then(() => {
                    this.closeSetUpShopDialog();
                    this.$emit('submitSuccess');
                }).finally(() => {
                    this.submitLoading = false;
                });
            } else this.$message.warning(this.$i18n.t('order.label_please_fill'));
        });
    }

    closeSetUpShopDialog() {
        if (this.$refs.setUpShopForm) this.$refs.setUpShopForm.resetFields();
        this.dialogVisible = false;
    }
}
