
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({
    name: 'CustomVideo'
})
export default class CustomVideo extends Vue {
    @Prop({
        required: true,
        default: ''
    }) url!: string;

    showVideoBtn = true;

    @Watch('url')
    onUrlChange() {
        this.showVideoBtn = true;
        this.$nextTick(() => {
            this.bindVideoEvent();
        });
    }

    mounted() {
        this.bindVideoEvent();
    }

    bindVideoEvent() {
        // console.log('bindVideoEventbindVideoEventbindVideoEvent')
        const video: any = this.$refs.video;
        if (video) {
            video.addEventListener('ended', () => {
                video.load();
                this.showVideoBtn = false;
            }, false);
            video.addEventListener('play', () => {
                this.showVideoBtn = false;
            });
            video.addEventListener('pause', () => {
                this.showVideoBtn = true;
            });
            video.addEventListener('seeking', () => { // 开始移动进度条
                this.showVideoBtn = false;
            });
            video.addEventListener('seeked', () => { // 进度条已经移动到了新的位置
                video.play();
            });
            this.autoPlayVideo(video);
        }
    }

    autoPlayVideo(video: any) {
        video.muted = true;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio < 1 && !video.paused) {
                        video.pause();
                    } else if (entry.intersectionRatio === 1 && video.paused) {
                        video.play();
                    }
                });
            },
            { threshold: 1 }
        );
        observer.observe(video);
    }

}
