































































































































































































import SetUpShopDialog from './components/SetUpShopDialog.vue';
import SetUpShopSuccessDlg from './components/SetUpShopSuccessDlg.vue';
import CustomVideo from '@/components/CustomVideo.vue';
import { Vue, Component, Watch } from 'vue-property-decorator';
// import { getCurrentCountry, ladingPageApply } from '@/api/user';
import sellerLandingPageVideoEN from '@/assets/media/seller_landing_page_en.mp4';
import sellerLandingPageVideoTH from '@/assets/media/seller_landing_page_th.mp4';
import ecosystemCompressionVideo from '@/assets/media/ecosystem_compression.mp4';
import { AppModule } from '@/store/modules/app';
import { domainConfig } from '@/config';

@Component({
    name: 'EGGMallHome',
    components: {
        SetUpShopDialog,
        SetUpShopSuccessDlg,
        CustomVideo
    }
})
export default class EGGMallHome extends Vue {
    $refs: any;
    $router: any;

    ecosystemCompressionVideo: any = ecosystemCompressionVideo;

    isShowTopBtn = true;

    dialogSetUpShopVisible = false;

    setUpShopSource = 5;

    showSuccessDlg = false;

    currentCountyId = 0;

    backTopvisible = false;

    mobileMenuVisible = false;

    get supportCountryList() {
        return [{
            iconUrl: 'https://image-cdn.eggmalltech.com/flags/Asia-Pacific/thailand.png',
            contactNo: '+66 20263298'
        }, {
            iconUrl: 'https://image-cdn.eggmalltech.com/flags/Asia-Pacific/Indonesia.png',
            contactNo: '+62 81270813029'
        }, {
            iconUrl: 'https://image-cdn.eggmalltech.com/flags/Asia-Pacific/Vietnam.png',
            contactNo: '+84 2854329067'
        }];
    }

    get isMobile() {
        return navigator.userAgent.match(/mobile/i);
    }

    get sellerLandingPageVideo() {
        return AppModule.locale === 'th' ? sellerLandingPageVideoTH : sellerLandingPageVideoEN;
    }

    get locale() {
        return AppModule.locale;
    }

    @Watch('locale', { immediate: true })
    onLocaleChange() {
        this.changeTHFont();
    }

    created() {
        window.addEventListener('scroll', this.judgeBackUp);
    }

    mounted() {
        document.body.style.background = '#FFF';
        this.getCurrentCountryByIp();
        this.changeTHFont();
    }

    beforeDestroy() {
        document.body.style.background = '';
    }

    destroyed() {
        window.removeEventListener('scroll', this.judgeBackUp);
    }

    changeTHFont() {
        const welcomePage = document.getElementById('WelcomePage');
        if (welcomePage) {
            welcomePage.style.fontFamily = this.locale === 'th' ? 'Prompt-Regular' : '';
        }
    }

    private getCurrentCountryByIp() {
        // getCurrentCountry().then((res: any) => {
        //     this.currentCountyId = +res.data;
        // })
    }

    private backToTop() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }

    private openWebsite(type: string, path?: string) {
        window.open(domainConfig[type] + (path || ''));
    }

    private async openSetUpshopDlg(source = 5) {
        // ladingPageApply({
        //     countryId: this.currentCountyId || 1001,
        //     landingPageType: 2,
        //     businessPlatform: navigator.userAgent.match(/mobile/i) ? 201 : 200,
        //     applySystemElementId: '265'
        // });
        this.setUpShopSource = source;
        this.dialogSetUpShopVisible = true;
    }

    private submitSuccess() {
        setTimeout(() => {
            this.showSuccessDlg = true;
        }, 200);
    }

    judgeBackUp() {
        this.backTopvisible = document.documentElement.scrollTop > 300;
    }

    openFBLInk() {
        window.open('https://www.facebook.com/eggmallsupport');
    }

    openLineLInk() {
        window.open('https://bit.ly/LINEforweb');
    }

    openEmailLInk() {
        window.open('mailto:customerservice@whats-egg.com');
    }

    openPhone() {
        window.open('tel:02-0263298');
    }
}
