




















import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component
export default class extends Vue {
    @PropSync('visible', {
        required: true,
        default: false
    })
    dialogVisible!: any;
}
